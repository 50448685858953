// SCSS
import '@scss/app.scss'
// Fortawesome
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
// Slick
import 'slick-carousel'
import 'slick-carousel/slick/slick.scss'
// GSAP
import gsap from 'gsap'

// Fortawesome 加入類型
library.add(fas, far, fab)
// Fortawesome 使用 Svg
dom.i2svg() 

// 執行序
$(() => {

  // Header
  let rwdNavToggle = false
  const $rwdNavBtn = $('#rwdNavBtn')
  const $lines = $rwdNavBtn.find('span')
  const $rwdNavLightboxBg = $('#rwdNavLightboxBg')
  const $rwdNavLightbox = $('#rwdNavLightbox')
  const rwdNav = () => {
    gsap.to($lines[0], {
      rotate: rwdNavToggle ? 45 : 0,
      y: rwdNavToggle ? 6 : 0,
      background: rwdNavToggle ? 'rgb(17, 24, 39)' : '',
      direction: 0.2,
      ease: 'expo'
    })
    gsap.to($lines[1], {
      scaleX: rwdNavToggle ? 0 : 1,
      background: rwdNavToggle ? 'rgb(17, 24, 39)' : '',
      direction: 0.2,
      ease: 'expo'
    })
    gsap.to($lines[2], {
      rotate: rwdNavToggle ? -45 : 0,
      y: rwdNavToggle ? -6 : 0,
      background: rwdNavToggle ? 'rgb(17, 24, 39)' : '',
      direction: 0.2,
      ease: 'expo'
    })
    gsap.to($rwdNavLightboxBg, {
      autoAlpha: rwdNavToggle ? 1 : 0,
      direction: 0.2,
      ease: 'expo'
    })
    gsap.to($rwdNavLightbox, {
      xPercent: rwdNavToggle ? -100 : 0,
      direction: 0.2,
      ease: 'expo',
      onStart: () => {
        $rwdNavBtn.css('pointer-events', 'none')
        rwdNavToggle && $('body').css('overflow', 'hidden')
      },
      onComplete: () => {
        $rwdNavBtn.css('pointer-events', 'auto')
        !rwdNavToggle && $('body').removeAttr('style')
      }
    })
  }
  $rwdNavBtn.on('click', () => {
    rwdNavToggle = !rwdNavToggle
    rwdNav()
  })
  $rwdNavLightboxBg.on('click', () => {
    rwdNavToggle = false
    rwdNav()
  })
  window.addEventListener('resize', () => {
    if (window.matchMedia('(min-width: 1024px)').matches) {
      gsap.set($lines[0], { clearProps: 'all'})
      gsap.set($lines[1], { clearProps: 'all'})
      gsap.set($lines[2], { clearProps: 'all'})
      gsap.set($rwdNavLightboxBg, { clearProps: 'all'})
      gsap.set($rwdNavLightbox, { clearProps: 'all'})
    }
  })

  // 首頁輪播圖片
  $('.slider').slick({
    fade: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: 'cubic-bezier(.77, 0, .175, 1)',
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    useTransform: false,
    arrows: false,
    dots: false
  })
  $('#prevSlider').on('click', () => {
    $('.slider').slick('slickPrev')
  })
  $('#nextSlider').on('click', () => {
    $('.slider').slick('slickNext')
  })

  // 實景影片燈箱
  let filmLightboxToggle = false
  const $film = $('#film')
  const $filmObjs = $film.find('button')
  const $filmLightbox = $('#filmLightbox')
  const $box = $filmLightbox.find('>div')
  const filmLightbox = (e) => {
    gsap.to($filmLightbox, {
      autoAlpha: filmLightboxToggle ? 1 : 0,
      duration: 0.2,
      ease: 'none',
      onStart: () => {
        if (filmLightboxToggle) {
          $('body').css('overflow', 'hidden')
          const video = $(e.currentTarget).data('video')
          $box.append(`<iframe width="560" height="315" src="${video}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`)
        }
      },
      onComplete: () => {
        if (!filmLightboxToggle) {
          $box.empty()
          $('body').removeAttr('style')
        }
      }
    })
  }
  $filmObjs.on('click', (e) => {
    filmLightboxToggle = true
    filmLightbox(e)
  })
  $filmLightbox.on('click', (e) => {
    filmLightboxToggle = false
    filmLightbox(e)
  })

})
